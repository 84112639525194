@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

.App-footer {
  position: fixed;
  bottom: 3vh;
  left: 0;
  right: 0;
  background-color: transparent; /* 金黃色背景 */
  z-index: 500;
}

.footer-content {
  display: flex;
  gap: 2vw;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding:0 0.5vw 0 0.5vw;
  font-weight: bold;
}

.footer-link {
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFDD00; /* 亮藍色按鈕 */
  padding: 1vw;
  border: 1vw solid #000000; /* 黑色邊框 */
  box-shadow: 0.5vw 0.5vw 0 #ffffff; /* 像素風格陰影 */
  height: 100%; /* 稍微减小高度 */
  width: 20vw; /* 佔滿網格單元格 */
  border-radius: 2.5vw;
  
}

.footer-icon {
  font-size: 4vh; /* 稍微縮小圖標 */
  margin-bottom: 1vh;
  color: #000000; /* 白色圖標 */
}

.footer-text {
  font-size: 1.5vh; /* 稍微增加文字大小 */
  font-weight: bold; /* 加粗文字 */
  text-transform: uppercase;
  color: #000000;
}

.footer-link.active .footer-item {
  background-color: #e4b92f; /* 活躍狀態為粉紅色 */
  box-shadow: 1px 1px 0 #000000;
}

.game-link {
  grid-column: 3; /* 将GAME按钮放在中间列 */
  justify-self: center; /* 水平居中 */
}

.game-item {
  height: 100%; /* 增加高度 */
  width: 25vw; /* 增加宽度 */
  border-radius: 5vw; /* 保持圆形 */
  background-color: #FFDD00; /* 使用与其他按钮相同的背景色 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.game-icon {
  font-size: 5vh; /* 增加图标大小 */
  margin: 0 0 0.5vh 0;
  color: #000000; /* 保持黑色 */
}

.game-link:hover .game-item {
  box-shadow: 5px 5px 0 #ffffff; /* 增加悬停时的阴影 */
  transform: translateY(-5px); /* 悬停时稍微上移 */
}

.game-link.active .game-item {
  background: #e4b92f;
  box-shadow: 1px 1px 0 #000000;
}