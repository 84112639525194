.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    align-items: center; /* 垂直置中 */
    margin-top: 50px;
  }
  
  .loader-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loader {
    width: 50px;
    height: 50px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 30px; /* Added space between loader and text */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loader-container p {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 4px;
    color: #000;
    text-transform: uppercase;
    animation: fade 2s ease-in-out infinite;
  }
  
  @keyframes fade {
    0%, 100% { opacity: 0.3; }
    50% { opacity: 1; }
  }