root{
  background-color: rgb(255, 255, 255);
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* 禁止页面滚动 */
  background-color: rgb(255, 255, 255);
  height: auto;
}
.Loading{
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
}
.Root{
  position: fixed;
  height:100%;
  width: 100%;
  z-index: 50;  
}
.background-gif
{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 0;
}

.AppContent {
  font-family: "Raleway", serif;
  display: flex;
  flex-direction: column;
  height: 88vh;
  color: black;
  text-align: center;
  border: none; /* 移除白色邊框 */
  margin: 0; /* 确保没有外边距 */
  padding: 0; /* 确保没有内边距 */
}

.raleway-custom {
 
  font-optical-sizing: auto;
  font-weight: 400; /* 可以根据需要调整 */
  font-style: normal;
}