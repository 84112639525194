.t-message {
    position: fixed;
    top: 10vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffcc00;
    color: #000;
    padding: 2vw;
    border-radius: 5px;
    border: 1vw solid #000;
    z-index: 1000;
    width: 80%;
    text-align: center;
  }
  .meme{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: #000;
    padding: 0px;
    z-index: 100;
    width: 85%;
    text-align: center;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .meme img{
  width: 100%;
  border-radius: 15px
  }
  .close-meme-button{
    position: absolute;
    bottom: 2vh;
    background: #ffb74a;
    color: #000000;
    border: none;
    padding: 1vh 3vh;
    border-radius: 25px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 11px;
    border: 2px solid #000;
    box-shadow: 3px 3px 0 rgb(0, 0, 0);
  }
  
  .airdrop-background{
    background-size: cover;
    background-position: center;
  }
  
  
  .day-mode .task-container{
    color: #000;
  }
  .night-mode .task-container{
    color: #fff;
  
  }
  /* 通用樣式 */
  .task-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: transparent;
    padding: 3vw;
    z-index: 1;
  user-select:none
  }
  
  .task-list {
    list-style-type: none;
    padding: 0px;
    background-color: transparent;
  }
  
  .day-mode .task-item{
    background-color: #eaffda;
    color: #000000;
    box-shadow: 5px 5px 0px rgba(0, 0, 0, 1);
  }
  
  .night-mode .task-item{
    background-color: #9BBFAB;
    color: #000000;
    border: 2px solid #000000;
  }
  
  .day-mode .reward-item{
    background-color: #fff4cf;
    color: #000000;
  }
  
  .night-mode .reward-item{
    background-color: #ffb74a;
    color: #000000;
    border: 2px solid #000000;
    
  }
  
  .task-item{
    border-radius: 3vh;
    margin-bottom: 2vh;
    padding: 1.5vh;
    display: flex;
    align-items: center;
    box-shadow: 2vw 2vw 0px rgba(0, 0, 0, 1);
    border: 2px solid #000000;  
  }
  /* 通用字體樣式 */
  
  .task-icon {
    font-size: 5vw;
    margin-right: 3vw;
  }
  
  .task-details {
    flex-grow: 1;
    padding: 1vw;
  }
  
  .task-name {
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 3vw;  
  }
  
  .task-points {
    font-size: 2.5vw;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .t-catz{
    width: 3vh;
    height: 3vh;
    filter: drop-shadow(0.5vh 0.5vh 0px rgba(0, 0, 0, 0.5));
  }
  
  /* 按鈕樣式 */
  
  .task-button {
    background: #ffb74a;
    color: #000000;
    border: none;
    padding: 3vw 5vw;
    border-radius: 5vw;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 2.5vw;
    border: 0.75vw solid #000;
    box-shadow: 1vw 1vw 0 rgb(0, 0, 0);
    justify-items: center;
  }
  
  
  
  .task-button:hover {
    background: linear-gradient(45deg, #f388ad, #e265e2);
  }
  
  .task-button.claim {
    background: #FFA726;
    cursor: default;
  }
  
  .task-button.completed {
    background: linear-gradient(45deg, #f388ad, #e265e2);
    cursor: default;
  }
  
  .countdown-container {
    border-top: none;
    border-bottom: 0.4vh solid #000000;
    border-radius: 0 0 1.5vh 1.5vh; /* 只保留下半圆 */
    display: flex;
    flex-direction: column;
    background-color: #FFDD00;
    z-index: 10;
    box-shadow: 0px 1vh 0px rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, sans-serif;  
  }
  
  .countdown {
    display: flex;
    align-items: baseline;
  }
  
  .countdown span {
    margin: 0 1vw;
  }
  
  .time {
    font-size: 3vw;
    font-weight: bold;
  }
  
  .time-label {
    font-size: 2vw;
    color: #666;
  }
  