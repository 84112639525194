.message{
  
}
.cup-container{
    scroll-behavior: auto;
    overflow-y: scroll;
}

.cup-list {
  list-style-type: none;
  padding: 0vh 5vw;
  position: relative;
}

.cup-item {
  height: 23vh;
  margin: 2vh 0;
  border-radius: 5vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: relative;
  background-size: cover;
  color: white;
  background-blend-mode: multiply;
}

.cup-item::before,
.cup-item::after {
  content: '';
  position: absolute;
  width: 3vh;
  height: 3vh;
  background-color: #FFF;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.cup-item::before {
  left: -1.5vh;
}

.cup-item::after {
  right: -1.5vh;
}

.cup-detail span {
  color: white !important;
}

.cup-time{
    position: absolute;
    right: 3vw;
    top: 1vh;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 0.5vh 1vw;
    border-radius: 2vw;
}

.cup-button{
    position: absolute;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 1vh 5vw;
    z-index: 1;
    border-radius: 5vw;    
    border: 0;
    right: 3vw;
    bottom: 1vh;
    color: white;
}
.cup-button:hover{
    background-color: rgba(255, 255, 255, 0.25);
}
.cup-detail{
  position: absolute;
  left: 3vw;
  text-align: left;
}

.top{
  top: 1vh;
}

.bot{
  bottom: 1vh;
}
.close{
  font-size: large;
}

.modal{
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0000008a;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1vw);
  justify-items: center;
  align-items: center;
}
.modal-content{
  position: absolute;
  top: 5%;
  right: 50%;
  transform: translateX(50%);
  color: #FFF;
  margin-top: 5vh;
  width: 90%;
  height: 75%;
  background-color: #313131;
  border-radius: 5vw;
  align-items: center;
  justify-content: center;
}

.cuptab-content{
  height: 50vh;
  scroll-behavior: auto;
 overflow-y: scroll;
}

.cuptab-buttons{
  width: 100%;
  background-color: #f0f0f0;
  padding: 1vh 0;
  margin-bottom: 2vh;
  display: flex;
  justify-content: center;
}

.cuptab-buttons button{
  margin: 0 1vw;
  width: 25%;
  padding: 3vh ;
  font-size: 1.5vh;
  cursor: pointer;
  background-color: #ad7171;
  border: none;
  border-radius: 5vh;
  transition: background-color 0.3s;
}

.cuptab-buttons button:hover {
  background-color: #e0e0e0;
}

.cuptab-buttons button.active {
  background-color: #d0d0d0;
  color: #333;
}


.betButton{
  width: 80%;
  height: 10vh;
  font-size: 5vw;
  position: absolute;
  bottom: 5%;
  right: 50%;
  transform: translateX(50%);
  border-radius: 5vh;
  background-color: #bbbbbb5b;
}

.betButton:hover{
  background-color: #313131;
}