.Profile-container {
    width: 90vw;
    margin: 0 auto;
    padding: 2vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}

.Profile-content {
    width: 100%;
    margin-bottom: 2vh;
    text-align: center;
    justify-items: center;
}

.Bet-content {
    background-color: #cccccc;
    width: 95%;
    height: 60vh;
    text-align: center;
    justify-items: center;
    align-items: center;
    padding: 3vw;    
    border-radius: 5vh;
    box-shadow: 0 0.5vh 1vh rgba(0, 0, 0, 0.1);
    overflow-x: auto;
    display: flex;
    flex-direction: column;
}

.Bet-content::-webkit-scrollbar {
    display: none;
}

.tab-buttons {
    width: 90%;
    border-radius: 3vh;
    background-color: #f0f0f0;
    padding: 2vw;
    margin-bottom: 2vh;
    display: flex;
    justify-content: center;
}

.tab-buttons button {
    margin: 0 1vw;
    padding: 1vh 10vw;
    font-size: 1.5vh;
    cursor: pointer;
    background-color: #f0f0f0;
    border: none;
    border-radius: 3vh;
    transition: background-color 0.3s;
}

.tab-buttons button:hover {
    background-color: #e0e0e0;
}

.tab-buttons button.active {
    background-color: #d0d0d0;
    color: #333;
}

.tab-content {
    width: 100%;
    font-size: 2vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2vw;
    padding: 2vw;
}

.message {
    position: fixed;
    top: 2vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f8d7da;
    color: #721c24;
    padding: 1vh 2vw;
    border-radius: 0.5vh;
    box-shadow: 0 0.5vh 1vh rgba(0, 0, 0, 0.1);
}

.info-card {
    background-color: #e9e9e9;
    border-radius: 2vh;
    box-shadow: 0 0.5vh 1vh rgba(0, 0, 0, 0.1);
    padding: 2vw;
    margin: 2vw;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.info-card img {
    width: 10vh;
    height: 10vh;
    border-radius: 50%;
    margin-bottom: 1vh;
}

.info-card div {
    margin: 0.5vh 0;
    font-size: 1.8vh;
    color: #333;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* 确保弹出窗口在最上层 */
}

.popup-content {
    background-color: #fff;
    padding: 2vw;
    border-radius: 1vh;
    box-shadow: 0 0.5vh 1vh rgba(0, 0, 0, 0.2);
    width: 80vw;
    max-width: 500px;
    text-align: center;
    position: relative;
}

.popup-content button {
    position: absolute;
    top: 1vh;
    right: 1vw;
    background-color: #f0f0f0;
    border: none;
    border-radius: 1vh;
    padding: 0.5vh 1vw;
    cursor: pointer;
    transition: background-color 0.3s;
}
.popup-content img {
    height: 15vh;
}

.popup-content button:hover {
    background-color: #e0e0e0;
}